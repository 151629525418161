.craeteplan{
    margin-left:220px;  
}
.createplan-btn{
    background-image: linear-gradient(to right, #074b93, #0b0b58);
    color: #fff;
}
.create-plan-title{
    
    font-size: 18px;
}
.clear--btn{
    background-color: #fff;
    border: 2px solid #0b0b58;
    color: #074b93;
}
