.policy-page{
    position: relative;
}
.privacypolicy-page{
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;

}
.policy-img {
    width: 100%;
    max-height: 90%;
    position: fixed;
    object-fit: cover;
}

.policy-page {
    position: absolute;
    top: 6%;
    left: 0%;
    text-align: center;
    max-width: 100%;
    height: 95.7%;
    padding: 20px;
    /* background-color: rgba(255, 255, 255, 0.7);  */
    border-radius: 10px;
}

.policy-page::after {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 92.6%;
    background-color: rgba(13, 197, 248, 0.6);
    /* clip-path: polygon(10% 0%, 101% 97%, 0% 150%, 0% 0%); */
    transform: rotate(540deg);
    clip-path: polygon(10% 0%, 101% 97%, 0% 140%, 0% 0%);
}


.policy-page-content {
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
}

.policy-page h2 {
    font-size: 2rem;
    margin-top: 80px;
    color: #fff;
    margin-left: 450px;
}

.policy-page p {
    font-size: 1.1rem;
    line-height: 1.5;
    width: 50%;
    margin-left: 620px;
    text-align: justify;
    color: white;
    font-weight: 600;
}
.policy-otherInfo p{
    text-align: justify;
    padding: auto;
    
}
.policy-otherInfo{
    padding: 5px;
    margin: 10px;
}