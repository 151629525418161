.data-content {
  margin-left: 220px;
  margin-top: 15px;
}

.calendar-box {
  display: flex;
  align-items: center;
}

.right-aligned {
  color: #99cdf0;
  font-style: 10px;
}
.startdatebox,
.enddatebox {
  width: 150px;
  height: 31px;
  border: 1px solid #ccc;
  padding: 2.5px;
  gap: 5rem;
}

.fromtodatebox {
  border: 1px solid #f0eeee;
  margin-top: 5px;
  border-radius: 1px;
  padding: 5px;
  width: auto;
}

.clear-dates-btn {
  background-color: #f0eeee;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100px;
  height: 35.7px;
  text-align: center;
  margin-top: 0px;
  margin-left: 3px;
  cursor: pointer;
}

.add-record-button {
  background-image: linear-gradient(to right, #074b93, #0b0b58);
  color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: auto;
  text-align: center;
  margin-top: 53px;
  margin-left: 15px;
  cursor: pointer;
}

.filter-button {
  background-color: #dceefa;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  margin-bottom: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.filter-button:hover {
  background-color: #99cdf0;
}

.add-record-button:hover {
  background-color: #99cdf0;
}

.savebtn {
  background-color: #dceefa;
  padding: 4px;
  cursor: pointer;
  margin-right: 5px;
  border: none;
  font-size: 1.2rem;
}
.closebtn{
  cursor: pointer;
  margin-right: 5px;
  font-size: 1.3rem;
}

.removebtn {
  background-color: #dceefa;
  padding: 4px;
  cursor: pointer;
  margin-right: 5px;
  border: none;
  font-size: 1.4rem;
}

.select-userid-container {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #f4f1f1;
  width: auto;
  height: 52px;
  text-align: center;
  margin-top: 19px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  z-index: 1000;
}

.camping-title {
  font-size: 26px;

  
}

.selected-info {
  font-size: 0.8rem;
  color: #666;
  margin-top: 5px;
  text-align: center;
}


.clearuserid-btn {
  background-color: #f0eeee;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: auto;
  height: 35.7px;
  text-align: center;
  margin-top: 54px;
  margin-left: 8px;
  cursor: pointer;
}

.go-button {
  background-color: #fff;
  border: 2px solid #0b0b58;
  color: #074b93;
  padding: 10px;
  border-radius: 5px;
  width: auto;
  height: 35.7px;
  text-align: center;
  margin-top: 54px;
  margin-left: 8px;
  cursor: pointer;
}

.newtable-data {
  position: relative;
  z-index: 1;
  margin-top: 10px;

}
.data-content table th {
  color: white;
  background-color: #687481;
  font-size: 5px;
  font-weight: bold;
  height: 1rem;
  position: sticky; 
  top: 0; 
}
.data-content table tr:nth-child(even) {
  background-color: #cdd4d8;
}
.data-content table td {
  padding: 5px;
    font-size: 0.8rem;
}
.data-content table th:nth-child(1),
.data-content table td:nth-child(1) {
 text-align: center; 
}
.data-content table th:nth-child(1){
  width: 5rem;
  font-size: 18.5px;
}
.data-content table th:nth-child(2){
  width: 1rem;
  font-size: 18.5px;
}
.data-content table td:nth-child(2){
  text-align: center;
}
.data-content table th:nth-child(3){
  width: 9rem;
  text-align: center;
  font-size: 18.5px;
}
.data-content table td:nth-child(3){
  text-align: center;
}
.data-content table th:nth-child(4){
  width: 5rem;
  font-size: 18.5px;
}
.data-content table th:nth-child(5),
.data-content table th:nth-child(6),
.data-content table th:nth-child(7),
.data-content table th:nth-child(8) {
  text-align: center;
  font-size: 18.5px;
}
.data-content table .add-record-icon {
  font-size: 1.1rem;
  margin-top: 4px;
  margin-left: 15px;
  cursor: pointer;
}
.data-content table .savebtn,
.data-content table .removebtn {
  background: none;
  border: none;
  cursor: pointer;
}
.data-content .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.data-content table td:nth-child(2) {
  text-align: center;
}


.data-content table td:nth-child(4),
.data-content table td:nth-child(5),
.data-content table td:nth-child(6),
.data-content table td:nth-child(7) {
  text-align: right;
}
.data-content table td:nth-child(8) {
  text-align: center;
}

.editable-highlight {
  border: 1px solid #110e04;
}
.dialog-title {
  background-color: blue;
  color: white;
}

.dialog-content {
  padding: 20px;
}

.dialog-actions {
  padding: 10px 20px;
}

.dialog-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

@media (min-width: 1024px) and (max-width: 1279px) {

  .data-content {
    margin-left: 210px;

  }

  .savebtn {
    padding: 0px;
    margin-right: 0px;
    font-size: 1rem;
  }

  .removebtn {
    padding: 1px;
    margin-right: 0px;
    font-size: 1rem;
  }

  .newtable-data {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    width: 100%;

  }
}

@media screen and (max-width: 768px) {

  .data-content {
    margin-left: 230px;

  }

  .savebtn {
    padding: 0px;
    margin-right: 0px;
    font-size: 1rem;
  }

  .removebtn {
    padding: 1px;
    margin-right: 0px;
    font-size: 1rem;
  }

  .newtable-data {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    width: 100%;

  }

  .date-picker-container1 {
    margin-left: 5px;
    width: 15%;
  }

}


@media (min-width: 768px) and (max-width: 1023px) {

  .data-content {
    margin-left: 210px;
  }

  .savebtn {
    padding: 0px;
    margin-right: 0px;
    font-size: 1rem;
  }

  .removebtn {
    padding: 1px;
    margin-right: 0px;
    font-size: 1rem;
  }

  .newtable-data {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    width: 100%;

  }

  .date-picker-container1 {
    margin-left: 5px;
  }
}