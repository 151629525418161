.homepage {
  position:absolute;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  margin-top: 90px;
}

.homepage::-webkit-scrollbar {
  display: none;
}

.homepage::before {
  content: "";
  position: absolute;
  top: 12%;
  left: 0%;
  width: 100%;
  height: 87%;
  background-color: rgba(13, 197, 248, 0.7);
  clip-path: polygon(53% 0, 69% 74%, 0% 240%, 0% 0%);
  transform: rotate(540deg);
}

.hero-section {
  position: relative;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container {
  padding: 20px;
  margin-top: 50px;
  background-color: azure;
}

.content-section {
  display: flex;
  gap: 1rem;
  margin-top: 0px;
}

.hero-overlay h2 {
  position: absolute;
  top: 30%;
  left: 50%;
  font-size: 2rem;
  letter-spacing: 0.5px;
  background: linear-gradient( #074b93, #0b0b58);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
}

.hero-overlay h4 {
  position: absolute;
  top: 39%;
  left: 57%;
  font-size: x-large;
  letter-spacing:1.4px;
  background: linear-gradient( #074b93, #0b0b58);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


.left-box1,
.left-box2,
.left-box3,
.right-box,
.right-box1 {
  width: 45%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(19, 19, 19, 0.1);
  margin-top: 70px;
}

.home-img {
  height: 17rem;
  width: 100%;
}
.content-container h3 {
  font-weight: 500;
  font-size: larger;
  letter-spacing: 0.5px;
  text-align: left;
  text-shadow: 0 0 10px rgba(59, 11, 192, 0.1);
  color: #0cc0e4;
}

.box-content p {
  color: black;
  font-size: medium;
  letter-spacing: 0.5px;
  text-align: justify;
}

@media screen and (max-width: 768px) {
    .content-section {
    flex-direction: column;
  }

  .left-box1,
  .left-box2,
  .left-box3,
  .right-box,
  .right-box1 {
    width: 100%;
    margin-bottom: 20px;
  }

  .homepage::before {
    width: 100%;
    height: 87.4%;
    clip-path: polygon(50% 0, 69% 74%, 0% 240%, 0% 0%);
    transform: rotate(540deg);
  }
  .hero-overlay h2 {
    top: 25%;
    font-size: 1rem;
    left: 43%;
  }
  .hero-overlay h4 {
    top: 29%;
    left: 43%;
  }
  
  .know-more-btn { 
    margin-left: 70%;
    margin-top: 5px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {

  .homepage::before {
    width: 100%;
    height: 89%;
    clip-path: polygon(50% 0, 69% 74%, 0% 240%, 0% 0%);
    transform: rotate(540deg);
  }
  .hero-overlay h2 {
    top: 22%;
    left: 37%;
  }
  .hero-overlay h4 {
    top: 30%;
    left: 53%;
  }
  
  .know-more-btn { 
    margin-left: 120%;
    margin-top: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .homepage::before {
    width: 100%;
    height: 90%;
    clip-path: polygon(50% 0, 69% 74%, 0% 240%, 0% 0%);
    transform: rotate(540deg);
  }
  .hero-overlay h2 {
    top: 22%;
    left: 45%;
  }
  
  .hero-overlay h4 {
    top: 33%;
    left: 58%;
  }
  
  .know-more-btn { 
    margin-left: 160%;
    margin-top: 10px;
  }
  
}
@media (min-width: 1280px) and (max-width: 1439px) {

  .homepage::before {
    width: 100%;
    height: 90%;
    clip-path: polygon(50% 0, 69% 74%, 0% 240%, 0% 0%);
    transform: rotate(540deg);
  }
}
@media (min-width: 1440px) {
  .homepage {
    height: 100vh;
    margin-top: 70px;
  }
  .homepage::before {
    width: 100%;
    height: 90%;
    clip-path: polygon(50% 0, 69% 74%, 0% 240%, 0% 0%);
    transform: rotate(540deg);
  }

  .hero-section {
    position: relative;
    height: 80vh;
    display: flex;
  }

  .content-container {
    padding: 20px;
    margin-top: 50px;

  }
  .content-section {
    display: flex;
    gap: 1rem;
  }
  .active-paragraph {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

}
