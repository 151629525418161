
.navbar {
  width: 100%;
  height: 6.5rem;
  background-image: linear-gradient(to left, #074b93, #0b0b58);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  /* box-shadow: -0.08rem 0.70rem 0.90rem 0 rgba(196, 189, 189, 0.4); */
  position: fixed;
  top: 0;
  z-index: 1000;
}

.header {
  margin: auto;
  /* background-color: orchid; */
  width: 1210px;
  text-decoration: none;
  display: flex;
  gap: 2px;
}


.header__logo-st {
  width: 250px;
  
  height: 110px;
  margin-top: 5px;
  margin-right: 40px;
  /* object-fit: contain;  */
  cursor: pointer;
  /* background-color: rgb(28, 94, 152); */
}


.header__navigation {
  display: flex;
  gap: 3.5rem;
  /* background-color: pink; */
  margin-left: 0px;
  width: 37rem;
  margin-top: 27px;
}

.header__navigation a {
  display: block;
  text-decoration: none;
  color: white;
  font-weight: 700;
  border-bottom: 2px solid transparent;
}

.header__navigation a:hover {
  color: rgb(121, 233, 250);
  border-bottom: 2px solid #0cc0e4;
  
}

.header__login_signup {
  display: flex;
  /* justify-content: space-between; */
  width: auto;
  /* Adjusted width */
  height: 10%;
  gap: 1.1rem;
  margin-left: 0px;
  margin-top: 20px;
}

.header__login {
  background-image: linear-gradient( #074b93, #0b0b58);
  text-align: center;
  border-radius: 28px;
  width: 100px;
  color: white;
  padding: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.header__login a{
  color: white;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
}
.header__signup {
  background-color: #0cc0e4;
  text-align: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  width: 100px;
  padding: 11px;
}


.header__signup a {
  display: block;
  text-decoration: none;
  /* color: rgb(121, 233, 250); */
  /* color: #fff; */
  color: black;
  font-weight: 700;

}

.header__login a:hover {
  color: rgb(121, 233, 250);
}

.header__navigation a.active-link {
  border-bottom: 2px solid #0cc0e4;
  color: #0cc0e4;
  font-weight: 700;
}
.contact-no-link{
  flex-direction: column;
  color: white;
  letter-spacing: 0.1px;
  /* background-color: pink; */
  font-size: 12.5px;
  font-weight: bold;
  /* background-color: palegoldenrod; */
  height: 2.4rem;
  width: 15rem;
  margin-left: 0px;
}
/* body {
  padding-top: 70px;
} */
@media (min-width: 1440px) {

  .header__navigation {
    gap: 3rem;
    font-size: 20px;
  }
  
  
  .header__login_signup {
    font-size:20px;
    margin-top: 15px;
  }
}