.content1 {
  margin-left: 240px;
  padding: 5px;
  color: black;
}
.underline-blue {
  position: relative;
  display: inline-block;
}
.underline-blue::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  font-size: 26px;
  font-weight: bold; 
  width: 100%;
  height: 2.5px;
  background-color: #0cc0e4;
}
.content1 b {
  font-size: 25px;
}
.acc-info-form h4{
  margin-left: 20px;
}
.name-labels,.label-input2{
  display: flex;
  justify-content: space-between;
  margin-right: 266px;
  color: black;
  margin-top: 10px;

}
.name-labels,.label-input3{
  display: flex;
  margin-right: 405px;
  color: black;
  margin-top: 10px;

}
.name-fields {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.input-group,.label-input1{
  color: black;
  /* width: 20%; */
}
.from-date{
  color: #ccc;
}
.label-input {
  margin-right: 5px; 
  margin-top: 25px;
}

.emailip-fields{
  width: 55%;
  color: black;
}
.name-fields{
  width: 55%;
  /* margin-top: 30px; */
  color: black;
}
.passinput-box{
  width: 55%;
}
.save-btn{
  padding: 10px;
  width:80px;
  margin-top: 50px;
  /* margin-left: 200px; */
  cursor: pointer;
  background-color: rgb(150, 226, 226);
  color: black;
  border: none;
}
.cancel-btn{
  padding: 10px;
  width:80px;
  margin-top: 50px;
  margin-left: 50px;
  cursor: pointer;
  background-color:  #d7d9dd; 
  color: black;
  border: none;
}
.label-input1{
  margin-top: 20px;
  color: black;
}
.cnf-pass-ip{
  width:55%;
}
.profile-btn{
  background-image: linear-gradient(to right, #074b93, #0b0b58);
    color: #fff;
    font-weight: 700;
    width: auto;
    padding: 10px;
    align-items: center;
    border: none;
    border-radius: 5px;
    margin-left: 60%;
    margin-right: 1%; 
    margin-top: 15px;
    cursor: pointer;
}
.profile-btn1 {
  background-image: linear-gradient(to right, #074b93, #0b0b58);
    color: #fff;
    font-weight: 700;
    width:auto;
    padding: 10px;
    align-items: center;
    border: none;
    border-radius: 5px;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 15px;
    cursor: pointer;
}
