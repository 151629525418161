.contactus {
    /* text-align: center; */
    margin: 10px;
}

.contactus h3 {
    text-align: left;
    margin-left: 15px;
    font-size: 22px;
    margin-top: 110px;
    color: #0cc0e4;
}

.contactus p {
    margin-bottom: 10px;
    margin-left: 17px;
    font-weight: 550;
    text-align: left;
}

.contact-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 6px;
}

.contact-form {
    width: 50%;
    /* margin: 10px; */
    /* max-width: 800px; */
    padding: 2px;
    border: 1px solid #f1efef;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}


.form-group input,
.form-group textarea {
    width: 100%;
    padding: 3px;
    border: 1px solid #ccc1c1;
    border-radius: 5px;
}

.form-group textarea {
    resize: vertical;
}
.form-submitBtn {
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.contform-btn {
    background-image: linear-gradient(to right, #074b93, #0b0b58);
    color: #fff;
    font-weight: 700;
    width: 150px;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin: 15px 0px;
}

.contform-btn:hover {
    background-color: #0056b3;
}

.contact-address {
    width: 50%;
    max-height: 50%;
    padding: 0px;
    border: 1px solid #f7f2f2;
    border-radius: 1px;
    box-shadow: 0 0 10px rgba(193, 191, 191, 0.1);
    text-align: left;
    align-content: flex-start;

}

.contact-address p,
h4 {
    text-align: center;
    font-weight: bold;
}


@media screen and (max-width: 768px) {
    .contactus h3 {
        margin-left: 35px;
    }

    .contactus p {
        margin-left: 32px;
    }

    .contact-form {
        width: 90%;
    }

    .contact-address {
        width: 90%;
    }
}