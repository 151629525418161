.solution-container {
  margin-top: 20px;
}

.solution-content {
  text-align: center;
  margin-top: 120px;
}

.solution-content h3 {
  font-size: 34px;
  color: #0cc0e4;
  margin-bottom: 10px;
}

.solution-content p {
  font-size: 20px;
  font-weight: 500;
}

.solution-boxes {
  display: flex;
  margin: 10px;
  justify-content: space-between;
  gap: 20px;
}

.solution-btn {
  flex: 1;
  padding: 20px;
  border: 2px solid #0cc0e4;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.solution-btn:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.solution-btn h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #0cc0e4;
  text-align: center;
}

.solution-btn ul {
  padding-left: 20px;
  line-height: 20px;
}

.solution-btn ul li {
  font-size: medium;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .solution-boxes {
    flex-direction: column;
    margin-left: 20px;
  }

  .solution-btn {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
