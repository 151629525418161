.campaigndata {
    margin-left: 210px;
}

/* .view {
    margin-left: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 0px;
    margin-left: 17px;
    cursor: pointer;
} */

.form-container {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    border: 1px solid #a90f0f;
    background-color: rebeccapurple;
    width: 90%;
    height: 500px;
}

.form-row {
    margin-top: 15px;
}

.campaigndata .dept-ip {
    margin-left: 20px;
    margin-right: 50px;
    border-radius: 5px;
    width: calc(30% - 40px);
    margin-bottom: 30px;
}

.campaigndata .indus-ip {
    margin-left: 200px;
    margin-right: 20px;

}

.campaigndata .location-ip {
    width: 47%;
    height: 40px;
    margin-left: 200px;
    margin-bottom: 30px;
}

.button-group {
    margin-top: 10px;
    margin-left: 340px;
}

.button-group button {
    margin-right: 10px;
    padding: 10px;
    cursor: pointer;
}

.button-group button[type="submit"] {
    background-image: linear-gradient(to right, #074b93, #0b0b58);
    color: #fff;
    border: none;
    margin-left: 130px;
}

.button-group button[type="button"] {
    background-color: #fff;
    border: 2px solid #0b0b58;
    color: #074b93;
}

.create-campaign-box {
    display: inline-block;
    border: 0.5px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-left: 40px;
    margin-bottom: 10px;
    top: 0px;
    width: 50%;
}

.error-message {
    color: red;
}

.error-message-7-days {
    color: rgb(255, 72, 0);
    margin-left: 30px;
}
.add-new-button-container button{
    background-image: linear-gradient(to right, #074b93, #0b0b58);
    color: #fff;
    font-weight: 700;
    width: auto;
    padding: 5px;
    align-items: center;
    border: none;
    border-radius: 5px;
    margin-left: 85%;
    margin-right: 1%; 
    margin-top: 15px;
    cursor: pointer;
}
.spe-form-title{
    position: relative;
    margin-bottom: 15px;
    font-size: 25px;
}
.spe-form-title::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 16%;
    height: 2.5px;
    background-color: #0cc0e4;
}
.dialogue-title-spe{
    position: relative;

}
.dialogue-title-spe::after{
    content: '';
    position: absolute;
    bottom: 0px;
    left: 5%;
    width: 40%;
    height: 2.5px;
    background-color: #0cc0e4;
}
.specification-table {
    margin: 10px;
    border: 1px solid #ccc;
   
}

.specification-table th {
    padding: 9px;
    text-align: center;
    color: rgb(243, 234, 234);
    font-size: 18.5px;
    background-color: #687481;
    font-weight: bold;
    position: sticky; 
    top: 0; 
}

.specification-table td {
    text-align: center;
    font-size:13.5px;
}
.form-row {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Space between rows */
}

.form-group {
    display: flex;
    margin: 5px;
    gap: 16px; /* Space between elements in a row */
    width: 70%;
}

.form-group > .MuiTextField-root {
    flex: 1; /* Allows the elements to take equal space */
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 6px; /* Space between buttons */
    margin-top: 16px; /* Space above the buttons */
}

.specification-table tbody tr:nth-child(even) {
    background-color: #cdd4d8;
}

.specification-table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

.specification-table tbody tr:hover {
    background-color: #ece9e9;
}

.specification-table .status-bar {
    font-weight: bold;
}

.specification-table .status-bar.accepted {
    color: rgb(29, 140, 29);
}

.specification-table .status-bar.decline {
    color: red;
}

.specification-table .status-bar.requested {
    color: black;
}

.specification-table table th:nth-child(1) {
    text-align: center;
    
}

.specification-table table th:nth-child(2) {
    text-align: center;
    

}

.specification-table table td:nth-child(2) {
    text-align: center;
}

.specification-table table td:nth-child(3) ,
.specification-table table td:nth-child(4),
.specification-table table td:nth-child(5),
.specification-table table td:nth-child(6){
   text-align: left;
}



.newadd-btn {
    color: #0cc0e4;
    margin-left: 18px;
    cursor: pointer;
}

.table-title {
    color: #0cc0e4;
    font-weight: bold;
}

.back-btn {
    color: #0cc0e4;
    cursor: pointer;
    font-size: 27px;
}