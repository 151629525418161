.adminpanelsidebar {
    height: 100%;
    width: 12.3rem;
    position: fixed;
    /* margin-top: 30px; */
    background-color: #e2e6ea;
    padding-top: 20px;

  }
  .logout-popup{
    margin-top: 15px;
  }
  .page-links {
    display: flex;
    flex-direction: column;
  }
  
  .page-links > div {
    margin-bottom: 20px;
  }
  
  .dataInputLink a,
  .customerManagementLink a,
  .accountsLedgerLink a {
    padding: 10px;
    border-radius: 5px;
    color: #333; 
    text-decoration: none; 
    display: block;
    font-weight: bold;
    width: 11rem;
    transition: background-color 0.3s ease; 
  }
  
  .dataInputLink a:hover,
  .customerManagementLink a:hover,
  .accountsLedgerLink a:hover,
  .dataInputLink a:active,
  .customerManagementLink a:active,
  .accountsLedgerLink a:active {
    background-color:#d3e5f0; 
  }
  .page-links > div.active a {
    background-color:#cdd4d8 ;
    color: black; 
  }
  
.profiletitle{
  margin-left: 5px;
  /* margin-bottom: 20px; */
  font-size: 20px;
  color: rgb(62, 61, 61);
  font-family: 'Inter', sans-serif;
}
.campaigntitle{
  margin-left: 5px;
  margin-bottom: 20px;
  font-size: 20px;
  color: rgb(62, 61, 61);
  font-family: 'Inter', sans-serif;
}
.campaignicon{
  margin-right:5px;
}
.logout-option-sidebar{
  display: flex;
  background-color: #687481;
  box-shadow: -0.08rem 0.70rem 0.90rem 0 white(21, 20, 20, 0.4);
  color: black;
  width: 100%;
  height: 54px;
  top: 0;
  position: sticky;

}


.logout-option-sidebar a {
  color:white;
  text-decoration: none;
  /* padding: 5px 10px; */
  border-radius: 5px;
 margin-top: 12px;
 margin-left: 10px;
 font-size: 18.5px;
 cursor: pointer;
 font-weight: 500;
}
.logout-icon{
  color: aliceblue;
  margin-top: 15px;
  margin-left: 60px;
  font-size: 18.5px;
  font-weight: 500;
  cursor: pointer;
}


@media (min-width: 1024px) and (max-width: 1279px) {

  .adminpanelsidebar {
    width: 16%;
  }
  .logout-option-sidebar a {
   margin-left: 5px;
  }
  .logout-icon{
    margin-left: 40px;
  }
  .dataInputLink a,
  .customerManagementLink a,
  .accountsLedgerLink a {
    width: auto;
  }
}

@media (max-width: 768px) {
  
  .adminpanelsidebar {
    width: 32%;
  }
  .logout-option-sidebar a {
   margin-left: 5px;
  }
  .logout-icon{
    margin-left: 30px;
  }
  .dataInputLink a,
  .customerManagementLink a,
  .accountsLedgerLink a {
    width: auto;
  }

}

@media (min-width: 768px) and (max-width: 1023px) {
 
  .adminpanelsidebar {
    width: 21%;
  }
  .logout-option-sidebar a {
   margin-left: 5px;
  }
  .logout-icon{
    margin-left: 40px;
  }
  .dataInputLink a,
  .customerManagementLink a,
  .accountsLedgerLink a {
    width: auto;
  }
}

