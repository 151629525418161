.about-container {
    position: relative;
}

.aboutus-img-container {
    position: relative;
    width: 100%;
    height: 36rem;
    overflow: hidden;
}

.aboutus-img {
    width: 100%;
    height: 37rem;
}

.about {
    position: absolute;
    top: 0%;
    left: 0%;
    text-align: center;
    max-width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    /* background-color: rgba(181, 216, 223, 0.6); */
    background-color: azure;
}

.about::after {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(13, 197, 248, 0.6);
    transform: rotate(540deg);
    clip-path: polygon(45% 1%, 108% 97%, 0% 150%, 0% 1.1%);
}

.about-content {
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
}

.about h2 {
    font-size: 2rem;
    margin-top: 100px;
    background: linear-gradient(#074b93, #0b0b58);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-left: 450px;
}


.about p {
    z-index: 5px;
    font-size: larger;
    width: auto;
    line-height: 30px;
    margin-left: 550px;
    text-align: justify;
    background: #0b0b58;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    z-index: 5;  
    letter-spacing:1.5px;
    visibility: inherit;
    transition: none 0s ease 0s;
}

.team-work {
    padding: 15px;
    margin-bottom: 20px;
}

.team-work p {
    font-size: 2rem;
    width: 9.2rem;
    position: relative;
    text-align: center;
    margin: auto;
}

.team-work p::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background-color: #97d1e8;
    z-index: -1;
}


.team-info a i {
    /* color: #0073b1; */
    margin-right: 5px; /* Adjust spacing as needed */
}

.team-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7rem;

}

.team-row {
    display: flex;
    justify-content: center;
    gap: 12rem;
}

.team-box {
    width: 150px;
    text-align: center;
}

.team-img {
    width: 109%;
    height: 165px;
    border-radius: 100%;
    object-fit: cover;
}

.team-info {
    /* background-color: pink; */
    width: 10rem;
    margin-top: 10px;
}

.team-info a {
    font-weight: bold;
    color: black;
    text-decoration: none;
}
.team-info i {
    color: #0073b1;
}
.team-info a:hover {
    color: #0073b1;
}

.team-info p {
    margin: 10px 0 0;
    font-size: 14px;
}
@media (min-width: 1001px) and (max-width: 1107px) {
    .about h2 {
        font-size: 1.4rem;
        margin-top: 100px;
        background: linear-gradient(#074b93, #0b0b58);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-left: 450px;
    }
    
    
    .about p {
        z-index: 5px;
        font-size: 1.1rem;
    
    }
      

}