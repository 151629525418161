.table-container-wrapper {
  margin-left: 220px;
  /* position: relative; */
}

.exemngt-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 16%;
  height: 2.5px;
  background-color: #0cc0e4;
}

.table-container-wrapper {
  margin-top: 30px;
}

.table-container {
  overflow-y: auto;
margin-right: 13px;
}

.ledger-table th,
.ledger-table td {
  text-align: left;
  border: 1px solid #ddd;
  padding: 8px;
}

.ledger-table th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ledger-table th:first-child {
  left: 0;
  z-index: 2;
}

.ledger-table td:first-child {
  position: sticky;
  left: 0;
  background-color: #f2f2f2;
  z-index: 1;
}

.ledger-table tr {
  transition: background-color 0.3s;
}

.ledger-table tr:hover {
  background-color: #f5f5f5;
}