.logout-option-navbar {
    display: flex;
    /* justify-content: space-between; */
    /* background-color:#999999; */
    background-image: linear-gradient(to left, #074b93, #0b0b58);
    box-shadow: -0.08rem 0.70rem 0.90rem 0 white(21, 20, 20, 0.4);
    /* padding: 7px; */
    color: black;
    width: 100%;
    height: 85px;
    /* margin-right:100px; */
    top: 0;
    position: sticky;
}
.header__logo{
    height: 6.2rem;
    width: 13.5rem;
    margin: auto 20px;
}
