.status button {
  flex-grow: 1;
  padding: 8px;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  background: #404149;
  color: #ccc;
  width: 14%;
  margin-top: 5px;
}

.status,
.dailyActivity {
  margin-left: 20px;
}


.dashboard-content b {
  font-size: 26px;
  margin-bottom: 15px;
  padding: 3px;
  margin-left: 10px;
}

.dashboard-content {
  /* background: #cccfda; */
  height: auto;
  width: auto;
  margin-left: 230px;
  padding: 0px;
  align-items: flex-start;

}

.button-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.square-box {
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-left: 20px;
  margin-bottom: 10px;
  width: 51%;
  margin-top: 0px;
}

.date-picker-container {
  display: flex;
  gap: 10px;
  font-weight: bold;
  flex-direction: row;
}

/* .date-picker-container label {
  color: #0cc0e4;
  font-weight: bold;
} */



.status button.active {
  background-color: #5b8af7;
  color: white;
}

.additional-info1 {
  color: black;
  flex: row;
  margin-right: 600px;
}

.clear-button {
  background-color: lightsteelblue;
  border-color: #ccc;
  cursor: pointer;
  width: 55px;
  height: 37px;
  padding: 0px;
  margin-top: 5px;
  /* margin-right: 25px; */
}

.go-btn {
  width: 55px;
  height: 35px;
  margin-top: 0px;
  cursor: pointer;
  background-image: linear-gradient(to right, #074b93, #0b0b58);
  color: #fff;
}

.date-picker-container .DatePicker {
  height: 50px;
  /* Increase height */
  padding: 10px;
  /* Optional: Add padding for better look */
  font-size: 16px;
  /* Optional: Increase font size for better readability */
  background-color: #0cc0e4;
}

/* @media (max-width: 767px) {
  .status button {
    flex-grow: 1;
    padding: 8px;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    background: pink;
    color: #ccc;
    width: 14%;
    margin-top: 5px;
  }
} */


@media (min-width: 768px) and (max-width: 1023px) {
  /* holepage */
  .dashboard-content {
    height: auto;
    width: auto;
    margin-left: 230px;
    padding: 0px;
    align-items: flex-start;
  }

  .date-picker-container {
    display: flex;
    border: 1px solid black;
    padding: 6px;
    margin: 7px;
    width: 90%;
    gap: 10px;
    border-radius: 4px;
    font-weight: bold;
    flex-direction: row;
  }

  .datapicker_boxLabel {
    color: #0cc0e4;
    text-align: center;
    font-size: 20px;
    width: 32%;
    font-weight: bold;
  }

  .react-datepicker__input-container input {
    padding: 5px 10px;
    box-sizing: border-box;
  }

  .status button {
    padding: 10px;
    font-size: 15px;
    margin: auto;
    width: 30%;
  }

  .status,
  .dailyActivity {
    margin: 5px;
  }

  .status button.active {
    color: white;
  }

  .additional-info1 {
    margin: 0px;
    flex: row;
    margin-right: 600px;
  }

  .additional-info1 p {
    text-align: left;
    width: 400%;
    font-weight: bold;
  }

  .go-btn {
    margin-top: 10px;
  }


}

@media (min-width: 1024px) and (max-width: 1279px) {

  /* holepage */
  .dashboard-content {
    height: auto;
    width: auto;
    margin-left: 230px;
    padding: 0px;
    align-items: flex-start;
  }

  .date-picker-container {
    display: flex;
    border: 1px solid black;
    padding: 6px;
    margin: 7px;
    width: 70%;
    gap: 10px;
    border-radius: 4px;
    font-weight: bold;
    flex-direction: row;
  }

  .datapicker_boxLabel {
    color: #0cc0e4;
    text-align: center;
    font-size: 20px;
    width: 30%;
    font-weight: bold;
  }

  .react-datepicker__input-container input {
    padding: 5px 10px;
    box-sizing: border-box;
  }

  .status button {
    padding: 10px;
    font-size: 15px;

    margin: auto;
    width: 25%;
  }

  .status,
  .dailyActivity {
    margin: 5px;
  }

  .status button.active {
    color: white;
  }

  .additional-info1 {
    margin: 0px;
    flex: row;
    margin-right: 600px;
  }

  .additional-info1 p {
    text-align: center;
    width: 250%;
    font-weight: bold;
  }

  .go-btn {
    margin-top: 10px;
  }

}

@media (min-width: 1280px) and (max-width: 1480px) {

  /* holepage */

  .date-picker-container {
    display: flex;
    border: 1px solid black;
    padding: 10px;
    margin: 7px;
    width: 65%;
    gap: 10px;
    border-radius: 4px;
    font-weight: bold;
    flex-direction: row;
  }

  .datapicker_boxLabel {
    color: #0cc0e4;
    text-align: center;
    font-size: 20px;
    width: 20%;
    font-weight: bold;
  }

  .react-datepicker__input-container input {
    padding: 5px 20px;
    box-sizing: border-box;
  }

  .status button {
    padding: 10px;
    font-size: 15px;
    margin: auto;
    width: 25%;
  }

  .status,
  .dailyActivity {
    margin: 5px;
  }

  .status button.active {
    color: white;
  }

  .additional-info1 {
    margin: 0px;
    flex: row;
    margin-right: 600px;
  }

  .additional-info1 p {
    text-align: center;
    width: 150%;
    font-weight: bold;
  }

  .go-btn {
    margin-top: 10px;
  }

}

@media (min-width: 1481px) and (max-width: 1780px) {

  /* holepage */

  .date-picker-container {
    display: flex;
    border: 1px solid black;
    padding: 10px;
    margin: 7px;
    width: 55%;
    gap: 10px;
    border-radius: 4px;
    font-weight: bold;
    flex-direction: row;
  }

  .datapicker_boxLabel {
    color: #0cc0e4;
    text-align: center;
    font-size: 20px;
    width: 20%;
    font-weight: bold;
  }

  .react-datepicker__input-container input {
    padding: 5px 20px;
    box-sizing: border-box;
  }

  .status button {
    padding: 10px;
    font-size: 15px;
    margin: auto;
    width: 25%;
  }

  .status,
  .dailyActivity {
    margin: 5px;
  }

  .status button.active {
    color: white;
  }

  .additional-info1 {
    margin: 0px;
    flex: row;
    margin-right: 600px;
  }

  .additional-info1 p {
    text-align: center;
    width: 150%;
    font-weight: bold;
  }

  .go-btn {

    margin-top: 10px;
  }


}