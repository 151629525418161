footer {
    background-color: #f6f6f6; 
    padding: 20px;
    margin-top: 20px;
  }
  .footer-container {
    display: flex;
    justify-content: space-between; 
    max-width: 1500px;
    margin-top: 70px;

  }
  .footer-card {
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc; 
  }

  .footer-card1 {
    flex: 1;
    padding: 10px;
    border: none; 
    text-align: left;
    margin-left: 20px;
    max-width: 300px;
  }
  .footer-card1 a {
    text-decoration: none;
    color: black;
  }
  .footer-card2 {
    flex: 1;
    border: none;
    margin-left: 90px;
    text-align: left;
  }
  
  .footer-card2 b {
    text-align: left;
  }
  
  .footer-card2 a {
    display: flex;
    margin: 3px;
    color: black;
    text-decoration: none;
    /* color: #777; */
  }
  .footer-card2 a a.active {
    color:#0cc0e4;
  }
  
  .footer-card3 {
    flex: 1;
    padding: 5px;
    border: none; 
    text-align: left;
    margin-right: 40px;

  } 
  .footer-card4 {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    text-align: left;
    border: none;
  }
  .footer-card3 p {
    text-align: left;
  }
  .footer-card4 p {
    text-align: left;
  }
 .social-icons {
    display: flex;
    gap: 10px;
    margin-top: 10px;  
   
  }
    
  .social-icons i {
    font-size: 20px; 
    color: #0073b1;
    cursor: pointer;   
  }
  
  .social-icons svg {
    font-size: 21px; 
    color: #0073b1;
    background-color: none;
    padding: 2px; 
    border-radius: 60%;
    /* margin-bottom: 93px; */
    cursor: pointer;   
  }
  .footer-card2 h5 a { 
    font-size: 1rem;
    text-decoration: none;
  }
  .map-container{
    margin-right: 10px;
    margin-top: 20px;

  }
  
@media only screen and (max-width: 1200px) {
  .footer-container {
      flex-wrap: wrap;
  }
  
  .footer-card {
      flex-basis: 100%;
      margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .social-icons {
      margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {  .footer-container {
      flex-direction: column;
      align-items: center;
  }
  
  .social-icons {
      margin-top: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .footer-card {
      padding: 5px;
  }
}
@media (min-width: 1440px) {


  .footer-card1 {
    font-size: 20px;
  }
  
  .footer-card2 {
    font-size: 20px;
  }


  .footer-card3 {
    font-size: 20px;
  } 

  .linkedin-icon {
    width: 2.3rem;
    height: 2.1rem;
    padding: 5px;
  }
  
  .social-icons svg {
    font-size: 13px; 
    padding: 11px;   
  }
  .map-container{
    margin: 20px auto;

  }

}