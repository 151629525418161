.pricing-container {
    font-family: Arial, sans-serif;
    width: 90%;
    margin-top: 110px;
  }
  
  .pricing-table {
    width: 100%;
    margin-left: 20px;
    border-collapse: collapse;
    margin-bottom: 40px;
  }
  
  .pricing-table th,
  .pricing-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .pricing-table th {
    background-color: #f2f2f2;
  }
  
  .duration-button {
    background-color: #e0e0e0;
    border: none;
    padding: 10px 20px;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .duration-button.active {
    background-color: #0cc0e4;
    font-weight: bold;
  }
  
  .discount-label {
    display: inline-block;
    margin-left: 5px;
    background-color: #ffd700;
    padding: 2px 5px;
    border-radius: 5px;
    color: #333;
    font-size: 0.8em;
  }
  
  .buy-button {
    background-image: linear-gradient( #074b93, #0b0b58);
    color: white;
    border: none;
    margin: 10px;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-toggle-button {
    background-color: #0cc0e4;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Set column widths */
.pricing-table th:nth-child(1),
.pricing-table td:nth-child(1) {
    
    width: 22.5%; /* Duration column */
    text-align: left;
}

.pricing-table th:nth-child(2),
.pricing-table td:nth-child(2) {
    width: 26%; /* Basic Plan column */
}

.pricing-table th:nth-child(3),
.pricing-table td:nth-child(3) {
    width: 26%; /* Standard Plan column */
}

.pricing-table th:nth-child(4),
.pricing-table td:nth-child(4) {
    width: 26%; /* Pro Plan column */
}
  
@media (min-width: 1290px) and (max-width: 1407px) {
    .pricing-table th:nth-child(1),
    .pricing-table td:nth-child(1) {
        
        width: 20%; /* Duration column */
        text-align: left;
    }  
}
@media (min-width: 1408px) and (max-width: 1635px) {
    .pricing-table th:nth-child(1),
    .pricing-table td:nth-child(1) {
        
        width: 17%; /* Duration column */
        text-align: left;
    }  
}