/* campaign.css */
.campaignlist {
    margin-left: 220px;
    padding: 0px;
}

.specification-title {
    font-size: 1.5rem;
}


.cust-speci-list {
    width: 100%;
    position: relative;
    z-index: 1;
    margin-right: 20px;
    margin-top: 10px;
}
.cust-speci-list td {
    font-size: 14px;
}

  .cust-speci-list th {
    color: white;
    background-color: #687481;
    font-size: 5px;
    font-weight: bold;
    height: 1rem;
    position: sticky; 
    top: 0; 
  }
  .cust-speci-list tr:nth-child(even) {
    background-color: #cdd4d8;
  }
  .cust-speci-list td {
    padding: 5px;
      font-size: 0.8rem;
  }
  .cust-speci-list th:nth-child(1),
  .cust-speci-list td:nth-child(1) {
   text-align: center; 
  }
  .cust-speci-list th:nth-child(1){
    width: 5.2rem;
    font-size: 18.5px;
  }
  .cust-speci-list th:nth-child(2){
    width: 1.8rem;
    font-size: 18.5px;
  }
  .cust-speci-list td:nth-child(2){
    text-align: center;
  }
  .cust-speci-list th:nth-child(3){
    width: 9rem;
    text-align: center;
    font-size: 18.5px;
  }
  .cust-speci-list td:nth-child(3){
    text-align: center;
  }
  .cust-speci-list th:nth-child(4){
    width: 5rem;
    font-size: 18.5px;
  }
  .cust-speci-list th:nth-child(5),
  .cust-speci-list th:nth-child(6),
  .cust-speci-list th:nth-child(7),
  .cust-speci-list th:nth-child(9){
    text-align: center;
    font-size: 18.5px;
  }

  .cust-speci-list th:nth-child(8){
    width: 13rem;
    text-align: center;
    font-size: 18.5px;
  }
  .cust-speci-list .add-record-icon {
    font-size: 1.1rem;
    margin-top: 4px;
    margin-left: 15px;
    cursor: pointer;
  }
  .cust-speci-list .savebtn,
  .cust-speci-list .removebtn {
    background: none;
    border: none;
    cursor: pointer;
  }
  .data-content .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .cust-speci-list td:nth-child(2) {
    text-align: center;
  }
  
  
  .cust-speci-list td:nth-child(4),
  .cust-speci-list td:nth-child(5),
  .cust-speci-list td:nth-child(6),
  .cust-speci-list td:nth-child(7) {
    text-align: right;
  }
  .cust-speci-list td:nth-child(8) {
    text-align: center;
  }