.login-page-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.login-page {
  display: flex;
  width: 65%;
  height: 75%;
  border: 2px solid #f2f2f4;
  border-radius: 8px 8px 8px 8px;
  background: #fff;
  margin-left: 270px;
  margin-top: 60px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
}

.image-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-section img {
  /* width: 100%; */
  height: 100%;
  max-width: 100%;
  border-radius: 8px;
  /* border-radius: 8px 0 0 8px; */
}

.form-section {
  flex: 1;
  padding: 20px;
  border: 1px solid #dcdce1;
  border-radius: 0 8px 8px 0;
  background: #fff;
  color: black;
  overflow-y: auto;
  border: none;
  max-width: auto;
}

.lh-logo-text {
  font-size: 1rem;
  line-height: 5rem;
  position: relative;
  margin-left: 150px;
  margin-bottom: 70px;
  color: black;
}

.login-btn {
  width: 90%;
  height: 40px;
  cursor: pointer;
  margin-top: 40px;
  margin-left: 150px;
}

.signup-create-acc {
  width: 90%;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 15.5px;
  margin-top: 20px;
  border: 2px solid #eeeeee;
  color: black;
  background-color: white;
}

.signup-create-acc button {
  background-color: white;
  font-size: 14.5px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.signup-create-acc svg {
  margin-left: 140px;
}

.signup-btn {
  width: 40%;
  height: 40px;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-right: 50px;
}

.signup-btn,
.get-otp {
  width: 40%;
}

.get-otp {
  height: 40px;
  cursor: pointer;
}

.get-code-button,
.reset-passbtn {
  width: 40%;
  height: 40px;
  cursor: pointer;
}

.form-group input {
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 6px;
  height: 45px;
}

.otp-message {
  color: #fff;
}

.login-link {
  color: black;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}

.forgot-password-link {
  color: #009bff;
  /* text-align: center; */
  font-weight: 600;
  margin-left: 130px;
  margin-top: 20px;
  cursor: pointer;
}

.forgottitle,
.signuptitle,
.logintitle {
  color: black;
  text-align: center;
}

.logintitle {
  font-size: 31px;
  font-weight: bold;
  text-align: left;
  color: #243861;
}

.signuptitle {
  font-size: 31px;
  font-weight: bold;
  text-align: left;
  color: #243861;

}

.resettitle {
  font-size: 31px;
  font-weight: bold;
  text-align: left;
  color: #243861;
}

.signuptitle {
  font-size: 35px;
  text-align: left;
  color: #243861;
}

.login-eml {
  width: 90%;
  margin-top: 22px;
}

.login-pass {
  width: 90%;
  height: 40px;
  margin-top: 10.4px;
  margin-bottom: 25px;
}

.reset-field {
  margin-top: 30px;
  width: 88%;
}

.reset-passbtn {
  width: 88%;
  height: 40px;
  margin-top: 15px;
}

.forgot-password-link {
  color: '#009bff';
  font-weight: '600';
  margin-left: '100px';
  margin-top: '10px';
  cursor: 'pointer'

}

.orimage1 {
  filter: grayscale(100%);
  width: 90%;
  margin-top: 10px;
  /* margin-left: 70px; */
}

.google-content {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 1rem;
  margin-top: 10px;
  margin-left: 45px;
}

.password-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  width: 90%;
}
.already-acc-link{
color:#009bff;
font-weight: 600;
margin-left: 150px;
cursor: pointer;
}
.go-to-login{
color: #009bff;
font-weight: 600;
margin-left: 200px;
cursor: pointer;
margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  
  .forgot-password-link {
    font-size: 13px;
     width: 60%;
   
   }
   .already-acc-link{
     margin-left: 70px;
   }
   .go-to-login{
     margin-left: 150px;
     }
     .login-page {
      display: flex;
      width: 75%;
      height: 75%;
      
      margin-left: 90px;
     
    }
    
    .image-section {
    display: none;
    }
    .form-section {
      max-width: 100%;
    }
}
@media (min-width: 1024px) and (max-width: 1279px) {

  .forgot-password-link {
   font-size: 13px;
    width: 60%;
  
  }
  .already-acc-link{
    margin-left: 70px;
  }
  .go-to-login{
    margin-left: 150px;
    }
}

@media screen and (max-width: 768px) {
  
  .forgot-password-link {
    font-size: 13px;
     width: 60%;
   
   }
   .already-acc-link{
     margin-left: 70px;
   }
   .go-to-login{
     margin-left: 150px;
     }

}

@media (min-width: 1280px) and (max-width: 1439px) {
  
  .forgot-password-link {
    font-size: 13px;
     width: 60%;
   
   }
   .already-acc-link{
     margin-left: 70px;
   }
   .go-to-login{
     margin-left: 150px;
     }

}

@media (min-width: 768px) and (max-width: 1023px) {
 
   .already-acc-link{
     margin-left: 70px;
   }
   .go-to-login{
     margin-left: 150px;
     }
     .login-page {
      display: flex;
      width: 65%;
      height: 75%;
      
      margin-left: 90px;
     
    }
    .image-section {
    display: none;
    }
    .form-section {
      max-width: 70%;
      margin-left: 70px;
    }
}
